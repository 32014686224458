import React, { useEffect, useState , FC} from 'react';

import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import CTAButton from '../../CTAButton/CTAButton';
import './styles.scss';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';
import rappi from './assets/rappi.svg'
import telefonica from './assets/telefonica.svg'
import groupon from './assets/groupon.svg'
import claro from './assets/claro.svg'
import awto from './assets/awto.svg'
import logo from './assets/logo.svg'


const IndexHero: FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const steper = [
    {name: "index.hero.steper.step1"},
    {name: "index.hero.steper.step2"},
    {name: "index.hero.steper.step3"},
    {name: "index.hero.steper.step4"},
    {name: "kushki", image: logo}
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % steper.length);
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <section>
      <div className="index-hero">
        <div className="container">
          <div className="col-lg-6 col-sm-12 hero-animation-container">
            <h1 className="hero-animation-title">
              <i>
                <FormattedMessage id="index.hero.title" />
              </i>
            </h1>
            <div className="componente">
            {steper.map((item, index) => (
                <div
                  key={index}
                  className={`item ${index === currentIndex ? 'active' : ''}`}
                >
                  {item.image ? (
                    // Si es una imagen, renderizamos la imagen
                    <img src={item.image} alt={item.name} className='steper-image'/>
                  ) : (
                    // Si no es una imagen, renderizamos el texto
                    <h2 className="item-text">
                      <FormattedMessage id={item.name}/>
                    </h2>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-12 col-sm-12">
            <div className="mt-layout-3">
              <div className="hero-buttons">
                <CTAButton className="hero-sales-button">
                  <FormattedMessage id="buttons.sales"/>
                </CTAButton>
                <div className="hero-buttons-res">
                  <LocalizedLink className="button-paytech" to="/blog/del-2017-al-2023-u-kushki-rompe-otro-hito-al-convertirse-en-el-primer">
                    <FormattedMessage id="index.hero.button" />
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-trama mt-7"></div>
        <div className="partners">
          <div className="container">
            <div className="partners-container">
              <img src={rappi} alt="rappi"/>
              <img src={telefonica} alt="telefonica"/>
              <img src={groupon} alt="groupon"/>
              <img src={claro} alt="claro"/>
              <img src={awto} alt="awto"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )}

export default IndexHero;