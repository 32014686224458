import React, { useState, useEffect } from 'react';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import CTA from '../components/Index/IndexCTA/IndexCTA';
import Transactions from '../components/Index/IndexTransactions/IndexTransactions';
import SuccessCases from '../components/Index/IndexSuccessCases/IndexSuccessCases';
import Hero from '../components/Index/IndexHero/IndexHero';
import Press from '../components/Index/IndexPress/IndexPress';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';

interface IndexPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const IndexPage: React.FC<IndexPageProps> = ({pathContext, location}) => {
  const [screenWidth, setScreenWidth] = useState<number>(0);

  useEffect(() => {
    if( undefined !== window ){
      setScreenWidth(window.innerWidth)
    }
  }, []);
  
  useEffect(() => {
    // Start of crazyegg code
    if( undefined !== window ){
      (function(w) {
        const s = document.createElement(`script`);
        s.src = `//script.crazyegg.com/pages/scripts/0120/7444.js`;
        s.async = true;
        const e = document.getElementsByTagName(`script`)[0];
        e.parentNode?.insertBefore(s, e);
      })(window);
    }
  // End of crazyegg code
  })

  const navThemeSelector = (screen: number) => {
    if(screen >= 500){ 
      return "light"
    } else {
      return "default"
    }  
  }

  const content = () => (
    <>
      <SEO />
      <Hero />
      <Transactions />
      <SuccessCases />
      <Press />
      <CTA />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout navbarTheme={navThemeSelector(screenWidth)} render={ content } locale={pathContext.localeCode} location={location} />
}


export default IndexPage;
