import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import ArticleCarousel from './ArticleCarousel';
import './styles.scss';

const IndexPress: React.FC = ({ children }) => (
  <section className="press my-layout-5">

    <div className="articles-title">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h3>
              <FormattedMessage id="index.press.title"
                values={defaultFormatValues}
              />
            </h3>
            <p className="lead mb-layout-5 mb-md-0">
              <FormattedMessage id="index.press.subtitle"
                values={defaultFormatValues}
              />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="articles">
      <ArticleCarousel />
    </div>

  </section>
);

export default IndexPress;
