import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss'
import ArrowLink from '../../ArrowLink/ArrowLink';

import economistaLogo from './assets/images/ELECONOMISTA.png';
import republicaLogo from './assets/images/larepublica.png';
import mercurioLogo from './assets/images/elmercurio.png';
import bloomberLogo from './assets/images/bloomberg.png'; 
import forbesLogo from './assets/images/forbes-mexico.svg';
import techCrunchLogo from './assets/images/techcrunch.svg';
import financieroLogo from './assets/images/el-financiero.png'
import cnnLogo from './assets/images/cnn-chile.png'
import espectadorLogo from './assets/images/el-espectador.png'
import dfsud from './assets/images/dfsud.svg'
import thePaypers from './assets/images/the_paypers.png'
import YahooFinance from './assets/images/yahoo_finance.png'
import './styles.scss';

interface ArticleCarouselState {
  activeSlide: number;
}

const articles = [
  {
    content: `CNN: Aron Schwarzkopf, cofundador y CEO de la fintech señaló: “creo que es el mejor momento para la tecnología financiera que ha existido en la historia. Las razones son dos: por fin se puede abrir el mercado para que haya más jugadores y distribución, y entonces, lleguen los servicios financieros a todos los ciudadanos alrededor de Latinoamérica, que era algo muy pequeño (…); y también hacia los actores como los bancos, reguladores, que ya pasaron su primer episodio de las fintechs y ahora están muy cómodos creando fintechs ellos o haciendo muchas relaciones comerciales con fintechs”.`,
    link: `https://www.cnnchile.com/programas-completos/agenda-economica-aron-schwarzkopf-kushki-fintech-latinoamerica_20231010/`,
    logo: cnnLogo, //logo La republica
    source: `CNNChile`
  },
  {
    content: `Finance Yahoo: “We started this project back in 2017. The amount of resources and commitment driven into this massive regulatory and infrastructure project is a first for LatAm. We invite businesses to use it widely, either directly or build a payment company on top of us,” said Aron Schwarzkopf, co-founder and CEO of Kushki. “As the first regional acquirer, we will be able to make payment innovation more accessible and give stores and payment services providers more control over their transactions.”`,
    link: `https://finance.yahoo.com/news/kushki-becomes-first-regional-next-120000231.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHTmvQQ4j7-MQkRKTDfnD_09WqTnqto7ft9hOjRGsf_CcBK-TCo-VzTRro5qa4ozd04nSQWzULakCOl1XGSeXH7Ml9EZ2gRZYlEBtyAQGRq4j2wnGw4E1NYjRXBqGc5UXsSojv86i0hgxbRHMWQ5IiY6rzsFUBLkN3yn33croF7U#:~:text=As%20a%20result%2C%20Kushki%20anticipates,merchant%20acquirer%20in%20the%20region`,
    logo: YahooFinance, //logo La republica
    source: `Yahoo Finance`
  },
  {
    content: `Paypers: The company’s new merchant acquiring service is delivered through a single proprietary processing platform, making it one of the largest acquirers in the region by coverage. It is set to make card transactions increasingly accessible while concomitantly standardising and consolidating fragmentation throughout the area. Furthermore, it is set to further improve acceptance rates, overall settlement performance, and underwriting, with Kushki anticipating this to result in a 300% operations growth in the region.`,
    link: `https://thepaypers.com/payments-general/kushki-is-now-a-payment-acquirer-in-latam--1264875`,
    logo: thePaypers, //logo La republica
    source: `The Paypers`
  },
  {
    content: `DFSUD: La adquirencia es un proceso que involucra varios pasos clave: comienza cuando un cliente realiza un pago en un establecimiento, ya sea físico o virtual, utilizando una tarjeta de débito, crédito o prepago. En ese momento, los datos de la tarjeta y la transacción son capturados de manera segura y tokenizados por Kushki.`,
    link: `https://dfsud.com/banca-y-fintech/ceo-de-kushki-hemos-creado-un-sistema-de-plomeria-en-america-latina-y`,
    logo: dfsud, //logo La republica
    source: `DF | Sud`
  },
  {
    content: `Kushki, una compañía de tecnología de pagos, ha anunciado que se ha iniciado su proceso de registro como adquirente ante la Comisión Nacional Bancaria y de Valores (CNBV) en México, convirtiéndose en uno de los cinco adquirentes no bancarios en el país.`,
    link: `https://www.elfinanciero.com.mx/mundo-empresa/2023/03/07/kushki-profundiza-su-apuesta-en-mexico-operara-como-adquirente/`,
    logo: financieroLogo, //logo La republica
    source: `ElFinanciero`
  },
  {
    content: `Un estudio de Kushki y Américas Market Intelligence revela que 2023 será el año récord en nuevas soluciones de pago en Latinoamérica, haciendo que por primera vez en la historia el efectivo no sea la principal.`,
    link: `https://www.cnnchile.com/programas-completos/agenda-economica-fernando-araya-tenpo-actividad-chile_20230228/`,
    logo: espectadorLogo, //logo La republica
    source: `ElEspectador`
  },
  {
    content: `Even amid the economic downturn, startups in the region are continuing to garner financing rounds, and this week Ecuadorian paytech Kushki became the country’s first unicorn, with a valuation of over $1 billion, after receiving a $100 million extension for its Series B.`,
    link: `https://www.bloomberglinea.com/english/rounds-of-the-week-zippi-minds-paisa-and-cluvi-raise-capital/`,
    logo: bloomberLogo,// logo bloomberg
    source: `Bloomberg`,
  },
  {
    content: `La firma tecnológica de pagos Kushki ha superado una valuación privada de US$1.000 millones, convirtiéndose en el primer unicornio ecuatoriano, al adicionar US$100 millones a su ronda de inversión Serie B de US$86 millones que había recaudado a mediados del año pasado.`,
    link: `https://forbes.cl/negocios/2022-06-07/kushki-se-convierte-en-unicornio-con-adicion-de-us100-millones-en-ronda-de-inversion/`,
    logo: forbesLogo,
    source: `Forbes`,
  },
  {
    content: `Ecuadorian payments infrastructure company Kushki has raised $100 million in an extension to its Series B round, more than doubling its valuation to $1.5 billion.`,
    link: `https://techcrunch.com/2022/06/07/ecuadorian-payments-infrastructure-startup-kushki-lands-100m-at-a-1-5b-valuation/`,
    logo: techCrunchLogo,
    source: `TechCrunch`,
  },
  {
    content: `Kushki busca romper la concentración del sistema de pagos en México y América Latina`,
    link: `https://www.eleconomista.com.mx/sectorfinanciero/Kushki-busca-romper-la-concentracion-del-sistema-de-pagos-en-Mexico-y-America-Latina-20231010-0055.html`,
    logo: economistaLogo,//logo el economista
    source: `Eleconomista`
  },
  {
    content: `El avance de la tecnología y el comportamiento de los consumidores han transformado la forma en que se realiza y se recibe pagos. Con el mundo digital surgió el e-commerce y los pagos online, un sector en el que se abrió paso la PayTech Kushki, una empresa ecuatoriana que en seis años se ha convertido en Unicornio, es decir, se ha consolidado en la categoría de startups cuya valoración supera los 1.000 millones de dólares.`,
    link: `https://elmercurio.com.ec/2022/06/09/el-camino-de-kushki-para-convertirse-en-el-primer-unicornio-ecuatoriano/`,
    logo: mercurioLogo, //logo el mercurio
    source: `Elmercurio`
  },
  {
    content: `Kushki, la compañía de tecnología de pagos que conecta a Latinoamérica con los pagos, anunció hoy una extensión de US$100 millones en su recaudación de fondos de Ronda tipo Serie B, con una valoración superior a los US$1.000 millones de dólares.`,
    link: `https://www.larepublica.co/finanzas/kushki-fintech-de-pagos-alcanzo-la-categoria-unicornio-tras-cerrar-ronda-de-inversion-3379278`,
    logo: republicaLogo, //logo La republica
    source: `Larepublica`
  }
]

const params = {
  autoplay: {
    delay: 10000,
  },
  breakpoints: {
    0: {
      spaceBetween: 20,
    },
    768: {
      spaceBetween: 60,
    },
    1200: {
      spaceBetween: 60,
    }
  },
  // centeredSlides: true,
  coverflowEffect: {
    depth: 100,
    modifier: 1,
    rotate: 0,
    slideShadows: false,
    stretch: 0,
  },
  effect: 'coverflow',
  loop: true,
  slideToClickedSlide: true,
  slidesPerView: 'auto',
}

class ArticleCarousel extends React.Component<{}, ArticleCarouselState> {

  constructor(props: any) {
    super(props);

    this.state = {
      activeSlide: 0
    };
  }

  public render() {
    return (
      <Swiper {...params}>
        {
          articles.map((article) =>
            <div className="article-card" key={article.source}>
              <img
                className={`article-card__logo  ${article.source}`}
                src={article.logo} alt={`${article.source} logo`}
              />
              <p
                className="article-card__content"
              >
                ”{article.content}”
              </p>
              <ArrowLink
                to={article.link}
                color="primary"
                external={true}
                openInNewTab={true}
                rel="noreferrer"
              >
                Leer más
              </ArrowLink>
            </div>
          )
        }
      </Swiper>
    );
  }
}

export default ArticleCarousel;
