import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import ArrowLink from '../../ArrowLink/ArrowLink'

import rappiLogoImg from './assets/images/Rappi-Logo.svg'
import justoLogoImg from './assets/images/Justo-logo.svg'
import mundialLogoImg from './assets/images/Mundial-Logo.svg'
import claroLogoImg from './assets/images/Claro-Logo.svg'
import justoBgImg from './assets/images/justo-bg.png'

import './styles.scss'

const IndexSuccessCases: React.FC = () => {
  const intl = useIntl()

  return (
    <section className="py-layout-7 bg-light-gradient overflow-hidden">
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <h1 className="d-none d-md-block w-50 text-center mb-4">
            <FormattedMessage id="index.success_cases.title"/>
          </h1>
          <h1 className="d-block d-md-none text-center mb-7">
            <FormattedMessage id="index.success_cases.title"/>
          </h1>

          <ArrowLink
            to="/customers"
            color="primary"
          >
            <FormattedMessage id="index.success_cases.link"/>
          </ArrowLink>
        </div>

        <div className="row mt-layout-6">
          <div className="col">
            <div className="success-case-card">
              <div className="success-case-card__img">
                <img src={justoBgImg} alt={`${intl.formatMessage({id: 'index.success_cases.case_1.name'})} Img`}/>
              </div>
              <div className="success-case-card__content">
                <img src={justoLogoImg} alt={`${intl.formatMessage({id: 'index.success_cases.case_1.name'})} logo`} className="mb-4" />
                <p className="lead" style={{whiteSpace: 'pre-wrap'}}>
                  <i><FormattedMessage id="index.success_cases.case_1.content"
                    values={defaultFormatValues}
                  /></i>
                </p>
                <h5 className="mb-0">
                  <FormattedMessage id="index.success_cases.case_1.author"/>
                </h5>
                <p className="text-dark-gray-2">
                  <FormattedMessage id="index.success_cases.case_1.position"/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-deck mt-5">
          <div className="col-lg-4">
            <div className="success-case-card">
              <div className="success-case-card__content">
                <img src={rappiLogoImg} alt={`${intl.formatMessage({id: 'index.success_cases.case_2.name'})} logo`} className="mb-4" />
                <p style={{whiteSpace: 'pre-wrap'}} className="mb-0">
                  <FormattedMessage id="index.success_cases.case_2.content"
                    values={defaultFormatValues}
                  /></p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="success-case-card mt-4 mt-lg-0">
              <div className="success-case-card__content">
                <img src={mundialLogoImg} alt={`${intl.formatMessage({id: 'index.success_cases.case_3.name'})} logo`} className="mb-4" />
                <p style={{whiteSpace: 'pre-wrap'}} className="mb-0">
                <FormattedMessage id="index.success_cases.case_3.content"
                    values={defaultFormatValues}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="success-case-card mt-4 mt-lg-0">
              <div className="success-case-card__content">
                <img src={claroLogoImg} alt={`${intl.formatMessage({id: 'index.success_cases.case_4.name'})} logo`} className="mb-4" />
                <p style={{whiteSpace: 'pre-wrap'}} className="mb-0">
                <FormattedMessage id="index.success_cases.case_4.content"
                    values={defaultFormatValues}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IndexSuccessCases
