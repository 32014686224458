import React from 'react'

import LocalizedLink from '../LocalizedLink/LocalizedLink';
import { OutboundLink } from 'gatsby-plugin-google-gtag'


interface CTAButtonProps {
  big?: boolean
  children?: React.ReactNode
  className?: string
  theme?: 'primary' | 'light' | 'secondary'
  campaign?: string
}

const CTAButton: React.FC<CTAButtonProps> = ({children, big, className, theme, campaign}) => {
  const selectedTheme = theme ? theme : 'primary'

  const sendAnalyticsEvent = () => {
    if(typeof window !== 'undefined') {
      window.gtag('event', 'Clic - Crear Cuenta', {
        'event_category': 'Cliente Nuevo',
        'event_label': 'Cliente Nuevo - Crear Cuenta',
      })
    }
  }

  return (
    <LocalizedLink to="/form-lead">
      <button
        className={`
        btn--${selectedTheme}
        ${big ? 'btn--lg': ''}
        ${className} contact-dropdown-button-sales`
      }
      >
        {children}
      </button>
    </LocalizedLink>
  )
}

export default CTAButton
