import { Layers, Plant, Shield, Target } from '@kushki/suka-react-system-icons';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react'

import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';
import ArrowLink from '../../ArrowLink/ArrowLink';

import trxImg from './assets/images/trx.svg';
import TrelloImg from './assets/images/trello.inline.svg';

const IndexTransactions: React.FC = () => {

  return (
    <section className="py-layout-6 bg-light text-dark">
      <div className="container">
      <h2 className="mt-layout-3 mt-lg-0 text-center">
        <FormattedMessage id="index.transactions.title"/>
      </h2>
      <p className="mt-5 text-center">
        <FormattedMessage
          id="index.transactions.subtitle"
          values={defaultFormatValues}
        />
      </p>
        <div className="row align-items-center">
          <div className="col-12 col-lg-5 text-center bg-light-primary p-5">
            <img src={trxImg} alt="Transactions" className="img-fluid"/>
          </div>
          <div className="col-12 offset-lg-1 col-lg-6">
            <div className="row mt-layout-4">
              <div className="col-md-6 mb-layout-3">
                <Layers className="mb-3 text-kushki-green"/>
                <h6>
                  <FormattedMessage id="index.transactions.feature_1.title"/>
                </h6>
                <p>
                  <FormattedMessage id="index.transactions.feature_1.subtitle"/>
                </p>
              </div>
              <div className="col-md-6 mb-layout-3">
                <TrelloImg className="mb-3 text-kushki-green"/>

                <h6>
                  <FormattedMessage id="index.transactions.feature_2.title"/>
                </h6>
                <p>
                  <FormattedMessage id="index.transactions.feature_2.subtitle"/>
                </p>
              </div>
              <div className="col-md-6 mb-layout-3">
                <Target className="mb-3 text-kushki-green"/>
                <h6>
                  <FormattedMessage id="index.transactions.feature_3.title"/>
                </h6>
                <p>
                  <FormattedMessage id="index.transactions.feature_3.subtitle"/>
                </p>
              </div>
              <div className="col-md-6 mb-layout-3">
                <Shield className="mb-3 text-kushki-green"/>
                <h6>
                  <FormattedMessage id="index.transactions.feature_4.title"/>
                </h6>
                <p style={{whiteSpace: 'pre-wrap'}}>
                  <FormattedMessage
                    id="index.transactions.feature_4.subtitle"
                    values={{
                      ...defaultFormatValues,
                      a: (...chunks: any) => (<OutboundLink href="https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=kushki" target="_blank" className="text-light">{chunks}</OutboundLink> ),
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IndexTransactions
